

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.neko_logo {
  
  background: #000;
  height: 13vh;
  width: 13vh;  
  position: static sticky;
}

.mynavbar {
  background: black;
  height: 13vh;
}

.navText {
  margin-right: 30px;
  color: white;
  font-size: 17px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

span.navText:hover {
 color: aqua;
}

.neko_plays_text {
  background: #000;
  height: 7vh;

}

.message{
  color: red;
  size: 10px;
}

.page-background  {
  background-color: white;
  
  background-image: url('../src/images/catbg.png');
  width: 100%;
  height: 100%;
  padding: 20px;
  position: fixed;
  background-position: center top;
  background-repeat: no-repeat;
  
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
   
}

.home{
  width: 50%;
  height: 20%;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 100px;
  margin-right: 100px;

  justify-content: center;
  display: flex;

}



.play-btn {
  background-color: white;
  width: 150px;
  height: 60px;
  border-radius: 10px;
  border-color: #00e6e6;
  font-size: 20px;
  color: #00e6e6;
  padding: 4px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin: 20px;
}

.home-text{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #06F;
  margin: 20px;
  font-size: 50px;


}

button.play-btn:hover{
  background-color: #00e6e6;
  color: white;
  border: #00e6e6;
}

.play-div-main {
  margin: 10px;
  padding: 10px 30px 10px 30px;
  display: flex;
  flex-wrap: wrap;
  
  width: 100%;
  height: auto;
}

.playcard-btn {
  background-color: white;
  width: 140px;
  height: 50px;
  border-radius: 10px;
  border-color: #00e6e6;
  font-size: 18px;
  color: #00e6e6;
  padding: 4px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin: 20px;
}

button.playcard-btn:hover{
  background-color: #00e6e6;
  color: white;
  border: #00e6e6;
}

.game-card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 250px;
  height: auto;
  margin: 50px;
}

/* On mouse-over, add a deeper shadow */
.game-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.game-card-info {
  padding: 12px 16px;
  justify-content: center;
}

.game-card-img {
  height: 200px;
  width: 250px;
}

.profle-main-div{
  margin: 50px;
  box-shadow: 2px 4px 8px 2px rgba(0,0,0,0.2);
  padding: 100px 20px 20px 20px;
  height: 80%;
  width: 70%;
  position: relative;
  

}

.profile-pair-div {
  justify-content: left;
  flex-wrap: wrap;
  display: flex;
  margin: 10px;
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid gray;
}

.profile-keys {
  font-weight: 600;
  font-size: 16;
  margin-right: 20px;
}

.profile-values {
  margin-left: 20px;
  font-weight: 400;
  font-size: 16;
  left: 0;
}

.profile-info-view{
  margin: 20px;
  padding: 30px 50px 30px 50px;
  
  
}

.profile-heading {
  top: 0px;
  left: 5px;
  position: relative;
  font-size: 36px;
  position: absolute;
  font-weight: 500;
  margin: 20px 30px 40px 30px;

}

.edit-profile-text {
  bottom: 5px;
  color: rgb(0, 110, 255);
  margin-left: 20px;
  margin-top: 30px;
}

.nav-tabs-profile {
  color: black;
  font-weight: 600;
}

